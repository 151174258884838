import React, { Component } from 'react'
import { graphql } from 'gatsby'
import {
    Nav,
    Row, Col,
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { book, bookurl, messages } from '../util/book.js'

import Layout from '../components/layout'
import LocalImage from '../components/local-image';

import './../css/home.css'

class IndexPage extends Component {
    render() {


        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    // console.log(i18n)
                    const lang = i18n.language;
                    const path = "/"+lang
                    // console.log( 'language : ' + lang)

                    const latestChp = {
                        'arwS1': 10,    //--- CHANGE latest chapter
                        'arwS2': 1,    //--- CHANGE latest chapter
                    };

                    return (
                        <>
                        <Layout>
                            <div id="bodyPage" className="body-page body-bg">
                                <main role="main" className="main">
                                <Container>
                                    <div className="bookInfo">
                                        <Row className="mb-2">

                                            {/* ARW Book 1 Season 2 */}
                                            <Col md={6}>
                                                <Row className="mb-4 no-gutters overflow-hidden flex-md-row h-md-250 position-relative">
                                                    <Col md={5}>
                                                        <LocalImage name="arwS2_cover" attr={{className: "cover w-100 s-ongoing", alt:`Cover`}}/>
                                                        <span className="progress-tag s-ongoing">Ongoing</span>
                                                    </Col>
                                                    <Col className="p-4 mt-5">
                                                    <a href={ path + bookurl['arwS2index']}><div>
                                                        <h3 className="title">{book["arwS2"].bookSubTitle[lang]}</h3>
                                                        <div className="description">
                                                        <p className="">
                                                            <a href={ path + bookurl['arwS2index']} className="btn btn-lg px-5" >{messages[lang].TEXT181003}</a>
                                                        </p>
                                                        </div>
                                                    </div></a>
                                                    </Col>
                                                   
                                                </Row>
                                            </Col>

                                            {/* ARW Book 1 Season 1 */}
                                            <Col md={6}>
                                                <Row className="mb-4 no-gutters overflow-hidden flex-md-row h-md-250 position-relative">
                                                    <Col md={5}>
                                                        <LocalImage name="arw_cover" attr={{className: "cover w-100 s-completed", alt:`Cover`}}/>
                                                        <span className="progress-tag s-completed">Completed</span>
                                                    </Col>
                                                    <Col className="p-4 mt-5">
                                                    <a href={ path + bookurl['arwS1index']}><div>
                                                        <h3 className="title">{book.bookSubTitle[lang]}</h3>
                                                        <div className="description">
                                                        <p className="">
                                                            <a href={ path + bookurl['arwS1index']} className="btn btn-lg px-5" >{messages[lang].TEXT181003}</a>
                                                        </p>
                                                        </div>
                                                    </div></a>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                    </div>

                                </Container>

                                </main>
                            </div>
                        </Layout>
                        </>
                    )
                }}

            </NamespacesConsumer>
        )
    }
}

export default withI18next()(IndexPage)

export const query = graphql`
    query getHomeLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
